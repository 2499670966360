import React, { useState } from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";

import { StyledTable, StyledCallSplit } from "./style";
import { useDispatch } from "react-redux";
import { fetchProductDetails } from "../../../../core/redux/asyncActions/global";
import { StyledCircularProgress } from "../../../CircularProgress";
import { useTranslation } from "react-i18next";

export const Table = ({ columns, items, clickable=true }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const handleItemPopup = async (e, item) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        if (!isLoading) {
            setIsLoading(true);
            await dispatch(fetchProductDetails(item.id, 1));
            setIsLoading(false);
        }
    };

    const [productUnits, setProductUnits] = useState([]);

    const count = (id) => {
        var unitCount = 1;
        productUnits.map((units) => ((units[0] === id) ? (unitCount = units[1]) : undefined))
        return unitCount;
    }

    if (!columns || !columns.length) return null;

    return (
        <Box padding="0 5px" width="100%" position="relative">
            {isLoading && (
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "sticky",
                    top: "50%",
                    zIndex: 2
                }}>
                    <StyledCircularProgress />
                </div>
            )}
            <StyledTable style={{
                borderCollapse: "separate",
                borderSpacing: "0 10px",
                opacity: isLoading ? 0.5 : 1,
                pointerEvents: isLoading ? "none" : "auto"
            }}>
                <TableHead>
                    <tr>
                        {columns.map((col) => (
                            (col.id !== undefined) && (
                                <TableCell key={`header-${col.id}`} style={((`${col.id}` === "oemCode" || `${col.id}` === "vendor" || `${col.id}` === "product" || `${col.id}` === "akCode") ? { textAlign: "left" } : { textAlign: "center" })}>
                                    {col.label}
                                </TableCell>)
                        ))}
                    </tr>
                </TableHead>
                <TableBody>
                    {items && Boolean(items?.length) &&
                        items.map((item, index) => (
                            <React.Fragment key={item.id}>
                                <TableRow key={item.id}>
                                    {columns.map((col) => {
                                        const id = `${col.id}`;
                                        const cellValue =
                                            typeof col.valueGetter === "function"
                                                ? col.valueGetter(item)
                                                : item[col.valueGetter];
                                        const Renderer = col.renderer;
                                        const productUnitsCount = (unitsCount) => {
                                            let isNotInArray = true;
                                            productUnits.map((units) => (
                                                (units[0] === item.id) && (
                                                    units[1] = unitsCount,
                                                    isNotInArray = false,
                                                    setProductUnits([...productUnits]))
                                            ))
                                            if (isNotInArray) { setProductUnits([...productUnits, [item.id, unitsCount]]); }
                                        }
                                        return Renderer ? (
                                            (col.id !== undefined) && (
                                                <TableCell
                                                    key={`cell-${id}`}
                                                    id={col.id}
                                                    style={(id !== "quantity")
                                                        ? (id === "img" ? { cursor: "pointer", textAlign: "center", width: "7%" } : (id === "vendor" ? { cursor: "pointer", textAlign: "left", width: "9%" } : { cursor: "pointer", textAlign: "center", width: "8%" }))
                                                        : { textAlign: "center", width: "17%" }}
                                                    onClick={(handleItemPopup && id !== "quantity") ? (((e) => (handleItemPopup(e, item)))) : undefined}
                                                >
                                                    <Renderer
                                                        value={cellValue}
                                                        rowData={item}
                                                        units={count(item.id)}
                                                        unitsCount={productUnitsCount}
                                                        rowIndex={index}
                                                    />
                                                </TableCell>)
                                        ) : (
                                            (col.id !== undefined) && (
                                                <TableCell
                                                    key={`cell-${id}`}
                                                    id={col.id}
                                                    style={(
                                                        (id === "akCode") ?
                                                            { width: "9%", textAlign: "left", cursor: "pointer" }
                                                        : (
                                                            id === "product" ? {
                                                            width: "33%", textAlign: "left", cursor: "pointer", wordBreak: "break-word"
                                                            }
                                                            : (id === "oemCode") ? {
                                                                width: "9%", textAlign: "left", cursor: "pointer"
                                                            } : (id === "order_date") ? {
                                                                width: "18%", textAlign: "center", cursor: "pointer"
                                                            } : (id === "count") ? {
                                                                width: "10%", textAlign: "center", cursor: "pointer"
                                                            } : (id === "unit_price") ? {
                                                                width: "18%", textAlign: "center", cursor: "pointer"
                                                            } : (id === "tag_titles") ? {
                                                                width: "30%", textAlign: "center", cursor: "pointer"
                                                            } : (id === "user") ? {
                                                                width: "24%", textAlign: "center", cursor: "pointer"
                                                            } : {}
                                                        )
                                                    )}
                                                    onClick={handleItemPopup ? (((e) => (handleItemPopup(e, item)))) : undefined}
                                                >
                                                    {cellValue}
                                                </TableCell>)
                                        );
                                    })}
                                </TableRow>
                                {item.has_related_codes && (
                                    <TableRow style={{ position: "relative", top: "-10px", pointerEvents: "none" }}>
                                        <TableCell colSpan={columns.length} style={{ height: "14px", padding: 0, maxHeight: "20px"}}>
                                            {/* These 2 elements are needed in order to make it look like this is one table row of 1 item */}
                                            <div style={{
                                                height: "25px",
                                                width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                top: "-3px",
                                                position: "absolute",
                                                padding: "0px 0px 3px 5px",
                                                backgroundColor: "#FFFF",
                                                zIndex: 10,
                                                fontSize: "14px",
                                            }}>
                                                <StyledCallSplit/>&nbsp;{t("productsList.hasRelatedCodes")}
                                            </div>
                                            <div style={{
                                                height: "10px",
                                                backgroundColor: "white",
                                                position: "relative",
                                                top: "12px",
                                                boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
                                                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                                            }}>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </React.Fragment>
                        ))}
                </TableBody>
            </StyledTable>
        </Box>
    );
};
