import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Modal from "@material-ui/core/Modal";
import Tab from "@material-ui/core/Tab";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";

import {
    fetchProductDetailsVehicles,
    fetchItemPurchaseHistory,
    updatePricesDetails,
} from "../../../../core/redux/asyncActions/global";

import { ModalHeader } from "../../../ModalHeader";
import { ItemCard } from "../ItemCard";
import { TabPanel } from "./components/TabPanel";
import { InfoCodes } from "./components/InfoCodes";
import { AlternativeProducts } from "./components/AlternativeProducts";
import { LinkedVehicles } from "./components/LinkedVehicles";
import { ItemDetailsHeader } from "./components/ItemDetailsHeader/ItemDetailsHeader";
import { PurchaseHistory } from "./components/PurchaseHistory"

import {
    Filters,
    FilterLine,
    FilterTitle,
} from "../../style";
import { ModalContent, StyledModalBody, StyledTabs } from "./style";
import StyledPagination from "../../../Pagination";
import { OrangeSwitch } from "../../../Switch";
import { clearProductDetails } from "../../../../core/redux/actions/global";

const a11yProps = (index) => {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
};

export const ItemDetailsModal = ({
    isShowTabs = true,
    clearSelectedItem,
    onCloseDetails
}) => {
    const isMounted = useRef(false);
    const [tabInfo, setTabInfo] = useState([
        "productsList.detailsModal.tabs.alternativeProducts",
        "productsList.detailsModal.tabs.specification",
        "productsList.detailsModal.tabs.oemCodes",
        "productsList.detailsModal.tabs.linkedVehicles",
        "productsList.detailsModal.tabs.purchaseHistory",
    ]);

    const productItem = useSelector(
        (state) => state.global?.currentProduct || null
    );
    const lang = useSelector(
        (state) => state.global?.lang);
    const visibility = !!productItem;
    const theme = useTheme();
    const { t } = useTranslation();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const dispatch = useDispatch();

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [historyPage, setHistoryPage] = useState(1);
    const [totalHistoryPages, setTotalHistoryPages] = useState(1);

    const setPageNumber = (pageNumber) => {
        setPage(pageNumber);
    };
    const setHistoryPageNumber = (pageNumber) => {
        setHistoryPage(pageNumber);
    };

    const images = useSelector(
        (state) => state.global?.productDetails?.list?.images || []
    );
    const {
        related_items_data: relatedItems = [],
        referenced_items_data: productsDetails = [],
        tecdoc_criteria: tecdocCriteria = [],
        oem_codes: oemCodes = [],
        vehicles: linkedVehicles = [],
        purchaseHistory: purchaseHistory = []
    } = useSelector((state) => state.global?.productDetails?.list || {});
    const { isLoadingDetails, isLoadingVehicles, isLoadingPurchaseHistory } = useSelector(
        (state) => state.global?.productDetails || {}
    );
    const { with_discount, with_vat } = useSelector(
        (state) => state.global?.userData || {}
    );

    const [relatedCodesTab, setRelatedCodesTab] = useState(null);
    const [alternativeProductsTab, setAlternativeProductsTab] = useState(0);
    const [specificationTab, setSpecificationTab] = useState(1);
    const [oemCodesTab, setOemCodesTab] = useState(2);
    const [linkedVehiclesTab, setLinkedVehiclesTab] = useState(3);
    const [purchaseHistoryTab, setPurchaseHistoryTab] = useState(4);
    const setTabIndex = () => {
        if (Array.isArray(relatedItems) && relatedItems.length > 0) {
            const relatedCodesTabString = "productsList.detailsModal.tabs.relatedCodes";
            // If the Related Codes tab is not in the tab list, add it at the start
            if (!tabInfo.includes(relatedCodesTabString)) {
                setTabInfo((prevTabs) => [relatedCodesTabString, ...prevTabs]);
            }
            setRelatedCodesTab(0);
            setAlternativeProductsTab(1);
            setSpecificationTab(2);
            setOemCodesTab(3);
            setLinkedVehiclesTab(4);
            setPurchaseHistoryTab(5);
        } else {
            // If there are no relatedItems, remove the Related Codes tab and reset to default order
            setTabInfo((prevTabs) =>
                prevTabs.filter((tab) => tab !== "productsList.detailsModal.tabs.relatedCodes")
            );
            setRelatedCodesTab(null);
            setAlternativeProductsTab(0);
            setSpecificationTab(1);
            setOemCodesTab(2);
            setLinkedVehiclesTab(3);
            setPurchaseHistoryTab(4);
            setSelectedTab(0);
        }
    };
    useEffect(() => {
        setTabIndex();
    }, [relatedItems])

    const [isDiscount, setDiscount] = useState(with_discount)
    const [isVat, setVat] = useState(with_vat)

    const prevProductId = useRef(productItem?.id);
    useEffect(() => {
        // Compare the previous product ID with the current product item's ID
        // to change vat and discount filters to default if the product has changed
        // to eliminate bug when clicking on alternative or related product the
        // vat/discount filters do not reflect the actual price calculation
        if (prevProductId.current !== productItem?.id) {
            setDiscount(with_discount);
            setVat(with_vat);
        }
    prevProductId.current = productItem?.id;
}, [productItem, with_discount, with_vat]);

    const [selectedTab, setSelectedTab] = useState(0);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const getIds = () => {
        if (selectedTab === relatedCodesTab) {
            return `${productItem.id},${relatedItems.map((prod) => prod.id).join(",")},${productsDetails.map((prod) => prod.id).join(",")}`;
        }
        return `${productItem.id},${productsDetails.map((prod) => prod.id).join(",")}`;
    };

    const handleDiscountChange = ({ target: { checked } }) => {
        setDiscount(checked);
        const idsForProductAndDetails = `${productItem.id},${productsDetails.map((prod) => prod.id).join(",")}`;
        if (relatedCodesTab !== null) {
            dispatch(
                updatePricesDetails(idsForProductAndDetails, checked, isVat, false)
            );
            const idsForRelatedItems = `${productItem.id},${relatedItems.map((prod) => prod.id).join(",")}`;
            dispatch(
                updatePricesDetails(idsForRelatedItems, checked, isVat, false, true)
            );
        } else {
            dispatch(
                updatePricesDetails(idsForProductAndDetails, checked, isVat, false)
            );
        }
    };
    
    const handleVatChange = ({ target: { checked } }) => {
        setVat(checked);
        const idsForProductAndDetails = `${productItem.id},${productsDetails.map((prod) => prod.id).join(",")}`;
        if (relatedCodesTab !== null) {
            dispatch(
                updatePricesDetails(idsForProductAndDetails, isDiscount, checked, false)
            );
            const idsForRelatedItems = `${productItem.id},${relatedItems.map((prod) => prod.id).join(",")}`;
            dispatch(
                updatePricesDetails(idsForRelatedItems, isDiscount, checked, false, true)
            );
        } else {
            dispatch(
                updatePricesDetails(idsForProductAndDetails, isDiscount, checked, false)
            );
        }
    };

    useEffect(() => {
        if (!isMounted.current) {
            const ids = getIds();
            dispatch(updatePricesDetails(ids, isDiscount, isVat, false));
            isMounted.current = true; // Mark as mounted to avoid infinite loop
        }
    }, [dispatch, isDiscount, isVat, selectedTab, productItem.id, relatedItems, productsDetails]);


    useEffect(() => {
        const id = productItem.id;
        if (id && selectedTab === linkedVehiclesTab) {
            (async () => {
                const results = await dispatch(
                    fetchProductDetailsVehicles(id, page)
                );
                if (results.type !== "FETCH_PRODUCT_DETAILS_VEHICLES_FAILURE") {
                    setTotalPages(results?.payload?.count / 25);
                }
            })();
        }
    }, [dispatch, lang, productItem?.id, page, selectedTab]);

    useEffect(() => {
        const id = productItem.id;
        if (id && selectedTab === purchaseHistoryTab) {
            (async () => {
                const results = await dispatch(
                    fetchItemPurchaseHistory(id, historyPage)
                );
                if (results.type !== "FETCH_ITEM_PURCHASE_HISTORY_FAILURE") {
                    setTotalHistoryPages(results?.payload?.count / 25);
                }
            })();
        }
    }, [dispatch, lang, productItem?.id, historyPage, selectedTab]);

    const onClose = useCallback(() => {
        dispatch(clearProductDetails());
    }, [dispatch]);

    const handleClose = useCallback(() => {
        if (onCloseDetails) {
            onCloseDetails();
            dispatch(clearProductDetails());
        } else {
            dispatch(clearProductDetails());
        }
    }, [dispatch, onCloseDetails]);

    return (
        <Modal
            open={visibility}
            onClose={onClose}
            aria-labelledby="item-details-modal"
            aria-describedby="item-details-modal"
            disableEnforceFocus
            style={isPhoneScreen && { margin: "56px 3%" }}
        >
            <ModalContent isPhoneScreen={isPhoneScreen} style={{ overflowY: isOverlayVisible ? 'hidden' : 'auto' }}>
                <ModalHeader onClose={handleClose} />

                <StyledModalBody>
                    {isPhoneScreen ? (
                        <ItemCard
                            data={productItem}
                            showQuantity={true}
                            images={images}
                            productDetails={true}
                            setIsOverlayVisible={setIsOverlayVisible}
                        />
                    ) : (
                        <ItemDetailsHeader
                            data={productItem}
                            setIsOverlayVisible={setIsOverlayVisible}
                        />
                    )}
                    {isShowTabs && (
                        <Box padding="20px 0 0 0">
                            <Box overflow="auto">
                                <StyledTabs
                                    value={selectedTab}
                                    onChange={handleTabChange}
                                    aria-label="tabs"
                                >
                                    {tabInfo.map((tabKey, index) => (
                                        <Tab
                                            key={tabKey}
                                            label={t(tabKey)}
                                            {...a11yProps(index)}
                                            className={tabKey === "productsList.detailsModal.tabs.relatedCodes" ? "related-items-tab" : ""}
                                        />
                                    ))}
                                </StyledTabs>
                            </Box>
                            <TabPanel selected={selectedTab === relatedCodesTab} index={relatedCodesTab}>
                                {isPhoneScreen && (
                                    <Filters>
                                        <FilterLine>
                                            <FilterTitle>
                                                {t("productsList.withDiscount")}
                                            </FilterTitle>
                                            <FormControlLabel
                                                label=""
                                                control={
                                                    <OrangeSwitch
                                                        checked={isDiscount}
                                                        onChange={
                                                            handleDiscountChange
                                                        }
                                                    />
                                                }
                                            />
                                        </FilterLine>
                                        <FilterLine>
                                            <FilterTitle>
                                                {t("productsList.withVAT")}
                                            </FilterTitle>
                                            <FormControlLabel
                                                control={
                                                    <OrangeSwitch
                                                        checked={isVat}
                                                        onChange={
                                                            handleVatChange
                                                        }
                                                    />
                                                }
                                                label=""
                                            />
                                        </FilterLine>
                                    </Filters>
                                )}
                                {selectedTab === relatedCodesTab && !isPhoneScreen && isShowTabs && (
                                    <Filters>
                                        <FilterLine>
                                            <FilterTitle>
                                                {t("productsList.withDiscount")}
                                            </FilterTitle>
                                            <FormControlLabel
                                                label=""
                                                control={
                                                    <OrangeSwitch
                                                        checked={isDiscount}
                                                        onChange={handleDiscountChange}
                                                    />
                                                }
                                            />
                                        </FilterLine>
                                        <FilterLine>
                                            <FilterTitle>
                                                {t("productsList.withVAT")}
                                            </FilterTitle>
                                            <FormControlLabel
                                                control={
                                                    <OrangeSwitch
                                                        checked={isVat}
                                                        onChange={handleVatChange}
                                                    />
                                                }
                                                label=""
                                            />
                                        </FilterLine>
                                    </Filters>
                                )}
                                <AlternativeProducts
                                    productsDetails={relatedItems}
                                    isLoadingDetails={isLoadingDetails}
                                    isPhoneScreen={isPhoneScreen}
                                    onClose={onClose}
                                    clearSelectedItem={clearSelectedItem}
                                />
                            </TabPanel>
                            <TabPanel selected={selectedTab === alternativeProductsTab} index={alternativeProductsTab}>
                                {isPhoneScreen && (
                                    <Filters>
                                        <FilterLine>
                                            <FilterTitle>
                                                {t("productsList.withDiscount")}
                                            </FilterTitle>
                                            <FormControlLabel
                                                label=""
                                                control={
                                                    <OrangeSwitch
                                                        checked={isDiscount}
                                                        onChange={
                                                            handleDiscountChange
                                                        }
                                                    />
                                                }
                                            />
                                        </FilterLine>
                                        <FilterLine>
                                            <FilterTitle>
                                                {t("productsList.withVAT")}
                                            </FilterTitle>
                                            <FormControlLabel
                                                control={
                                                    <OrangeSwitch
                                                        checked={isVat}
                                                        onChange={
                                                            handleVatChange
                                                        }
                                                    />
                                                }
                                                label=""
                                            />
                                        </FilterLine>
                                    </Filters>
                                )}
                                {selectedTab === alternativeProductsTab && !isPhoneScreen && isShowTabs && (
                                    <Filters>
                                        <FilterLine>
                                            <FilterTitle>
                                                {t("productsList.withDiscount")}
                                            </FilterTitle>
                                            <FormControlLabel
                                                label=""
                                                control={
                                                    <OrangeSwitch
                                                        checked={isDiscount}
                                                        onChange={handleDiscountChange}
                                                    />
                                                }
                                            />
                                        </FilterLine>
                                        <FilterLine>
                                            <FilterTitle>
                                                {t("productsList.withVAT")}
                                            </FilterTitle>
                                            <FormControlLabel
                                                control={
                                                    <OrangeSwitch
                                                        checked={isVat}
                                                        onChange={handleVatChange}
                                                    />
                                                }
                                                label=""
                                            />
                                        </FilterLine>
                                    </Filters>
                                )}
                                <AlternativeProducts
                                    {...{
                                        productsDetails,
                                        isLoadingDetails,
                                        isPhoneScreen,
                                        onClose,
                                        clearSelectedItem,
                                    }}
                                />
                            </TabPanel>
                            <TabPanel selected={selectedTab === specificationTab} index={specificationTab}>
                                <InfoCodes
                                    codes={tecdocCriteria}
                                    {...{
                                        isLoadingDetails,
                                    }}
                                />
                            </TabPanel>
                            <TabPanel selected={selectedTab === oemCodesTab} index={oemCodesTab}>
                                <InfoCodes
                                    codes={oemCodes}
                                    {...{
                                        isLoadingDetails,
                                    }}
                                />
                            </TabPanel>
                            <TabPanel selected={selectedTab === linkedVehiclesTab} index={linkedVehiclesTab}>
                                <LinkedVehicles
                                    {...{
                                        linkedVehicles,
                                        isLoadingVehicles,
                                        isPhoneScreen,
                                    }}
                                />
                            </TabPanel>
                            <TabPanel selected={selectedTab === purchaseHistoryTab} index={purchaseHistoryTab}>
                                <PurchaseHistory
                                    {...{
                                        purchaseHistory,
                                        isLoadingPurchaseHistory,
                                        isPhoneScreen,
                                    }}
                                />
                            </TabPanel>
                        </Box>
                    )}
                    {selectedTab === linkedVehiclesTab && Boolean(linkedVehicles.length) && (
                        <Box
                            display="flex"
                            justifyContent={
                                "center"
                            }
                            padding="0 0 20px"
                        >
                            <StyledPagination
                                count={totalPages}
                                pageNumber={page}
                                setPageNumber={setPageNumber}
                            />
                        </Box>
                    )}
                    {selectedTab === purchaseHistoryTab && Boolean(purchaseHistory.length) && (
                        <Box
                            display="flex"
                            justifyContent={
                                "center"
                            }
                            padding="0 0 20px"
                        >
                            <StyledPagination
                                count={totalHistoryPages}
                                pageNumber={historyPage}
                                setPageNumber={setHistoryPageNumber}
                            />
                        </Box>
                    )}
                </StyledModalBody>
            </ModalContent>
        </Modal>
    );
};
