import {
    CHANGE_LANGUAGE,
    DELETE_NOTIFICATION,
    HIDE_MENU,
    SET_NOTIFICATION,
    SHOW_MENU,
    TOGGLE_MENU,
} from "../actionTypes/global";
import * as loginActionTypes from "../actionTypes/login";
import * as globalActionTypes from "../actionTypes/global";
import { LANGUAGES, scopeToCountry } from "../../translation/languages";
import i18n from "../../../i18n";
import { updateProductsPrices } from "../operations/global";
import { SEARCH_TYPE } from "../../../components/Header/Search";

export const defaultGlobalState = {
    isDrawerOpen: false,
    userAuthenticated: false,
    country: "",
    lang: LANGUAGES["English"].id,
    menuProducts: null,
    version: "1.0.1",
    notificationMessage: "",
    userData: null,
    vendors: [],
    products: [],
    publicProducts: [],
    currentProduct: null,
    productDetails: {
        list: {
            related_items_data: [],
            referenced_items_data: [],
            tecdoc_criteria: [],
            images: [],
            oem_codes: [],
            vehicles: [],
        },
        isVat: false,
        isDiscount: false,
        errorVehicles: null,
        errorDetails: null,
        isLoadingDetails: false,
        isLoadingVehicles: false,
    },
    oemVehicles: {
        units: [],
        categories: [],
        isLoading: false,
        error: null,
    },
    currentOemVehicles: {
        details: [],
        image: "",
        imageMap: [],
        isLoading: false,
        error: null,
    },
    customerSearchList: [],
    byCodeSearchList: [],
    favoritesList: [],
    availabilityNotificationsList: [],
    tagsList: [],
    tagItemList: [],
    loggingIn: false,
    loginError: "",
    priceOfferHistoryList: [],
    priceOfferHistoryItem: {
        data: [],
        isLoading: false,
    },
    productsLoading: false,
    selectedVendors: [],
    onlyInStock: false,
    orderHistoryList: [],
    orderHistoryItem: {
        data: [],
        isLoading: false,
    },
    selectedItemCategory: null,
    selectedItemGroup: null,
    selectedItemModel: null,
    selectedTag: null,
    searchVisible: false,
    searchQuery: "",
    searchType: SEARCH_TYPE.byCode,
    searchResetPage: false,
    yqList: [],
    yqParams: [],
    yqFindVehicle: [],
    yqListVehicles: [],
    yqVehicleInfo: [],
    pageCount: 0,
    newProductsVisible: false,
    invoices: {
        creditLimit: null,
        totalBalance: null,
        unusedCreditLimit: null,
        balance: null,
        latePayments: null,
        invoices: [],
        isLoading: false
    },
    isFormSubmitted: false,
    itemReturnList: [],
    itemReturnItem: {
        data: [],
        isLoading: false,
    },
    returnFormData: {
        locations: []
    }
};

const globalReducer = (state = defaultGlobalState, { type, payload }) => {
    switch (type) {
        case loginActionTypes.LOGIN_REQUEST: {
            return {
                ...state,
                userAuthenticated: false,
                loggingIn: true,
            };
        }
        case loginActionTypes.LOGIN_SUCCESS: {
            return {
                ...state,
                userAuthenticated: true,
                loggingIn: false,
            };
        }
        case loginActionTypes.LOGIN_FAILURE: {
            return {
                ...state,
                userAuthenticated: false,
                loggingIn: false,
                loginError: payload.response.data,
            };
        }
        case loginActionTypes.LOGOUT: {
            return {
                ...defaultGlobalState,
                userAuthenticated: false,
                lang: state.lang
            };
        }
        case globalActionTypes.FETCH_USER_DATA_REQUEST: {
            return {
                ...state,
                userData: {
                    ...state.userData,
                    isLoading: true,
                }
            };
        }
        case globalActionTypes.FETCH_USER_DATA_SUCCESS: {
            return {
                ...state,
                userData: {
                    ...payload,
                    isLoading: false,
                },
                country: scopeToCountry(payload.scope),
            };
        }
        case globalActionTypes.FETCH_MENU_PRODUCTS_REQUEST: {
            return {
                ...state,
                menuProducts: "loading",
            };
        }
        case globalActionTypes.FETCH_MENU_PRODUCTS_SUCCESS: {
            return {
                ...state,
                menuProducts: payload,
            };
        }
        case globalActionTypes.UPDATE_PRICES_REQUEST:
        case globalActionTypes.FETCH_PRODUCTS_REQUEST: {
            return {
                ...state,
                productsLoading: true,
            };
        }
        case globalActionTypes.FETCH_PRODUCTS_SUCCESS: {
            const lengthHistory = state.userData?.code_search_history?.length;
            const codeHistory = lengthHistory === 10
                ? state.userData?.code_search_history?.slice(
                    0,
                    lengthHistory - 1
                )
                : state.userData?.code_search_history;
            return {
                ...state,
                userData: {
                    ...state.userData,
                    code_search_history: codeHistory ? [state.searchQuery, ...codeHistory] : [],
                },
                products: payload.results,
                pageCount: payload.count / 25,
                productsLoading: false,
            };
        }
        case globalActionTypes.FETCH_PUBLIC_PRODUCTS_REQUEST: {
            return {
                ...state,
                productsLoading: true,
            };
        }
        case globalActionTypes.FETCH_PUBLIC_PRODUCTS_SUCCESS: {
            return {
                ...state,
                publicProducts: payload.results,
                pageCount: payload.count / 25,
                productsLoading: false,
            };
        }
        case globalActionTypes.UPDATE_PRICES_SUCCESS: {
            return {
                ...state,
                products: updateProductsPrices(payload, state.products),
                productsLoading: false,
            };
        }
        case globalActionTypes.UPDATE_PRICES_FAILURE:
        case globalActionTypes.FETCH_PRODUCTS_FAILURE: {
            return {
                ...state,
                productsLoading: false,
            };
        }
        case globalActionTypes.FETCH_PUBLIC_PRODUCTS_FAILURE: {
            return {
                ...state,
                productsLoading: false,
            };
        }
        case globalActionTypes.FETCH_VENDORS_SUCCESS: {
            return {
                ...state,
                vendors: payload,
            };
        }
        case SET_NOTIFICATION:
            return {
                ...state,
                notificationMessage: payload,
            };
        case DELETE_NOTIFICATION:
            return {
                ...state,
                notificationMessage: "",
            };
        case TOGGLE_MENU:
            return {
                ...state,
                isDrawerOpen: !state.isDrawerOpen,
            };
        case SHOW_MENU:
            return {
                ...state,
                isDrawerOpen: true,
            };
        case HIDE_MENU:
            return {
                ...state,
                isDrawerOpen: false,
            };
        case CHANGE_LANGUAGE:
            i18n.changeLanguage(payload);
            return {
                ...state,
                lang: payload,
            };
        case globalActionTypes.CHANGE_COUNTRY_SUCCESS:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    scope: payload.scope,
                },
                country: scopeToCountry(payload.scope),
            };
        case globalActionTypes.SET_SELECTED_VENDORS:
            return {
                ...state,
                selectedVendors: payload,
                newProductsVisible: false,
            };
        case globalActionTypes.SET_ONLY_IN_STOCK:
            return {
                ...state,
                onlyInStock: payload,
                newProductsVisible: false,
            };
        case globalActionTypes.SET_SEARCH_VISIBLE:
            return {
                ...state,
                searchVisible: payload,
            };
        case globalActionTypes.SET_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: payload,
                searchResetPage: true,
                selectedTag: null,
                selectedItemCategory: null,
                selectedItemGroup: null,
                selectedItemModel: null,
                newProductsVisible: false,
            };
        case globalActionTypes.SET_SEARCH_RESET_PAGE:
            return {
                ...state,
                searchResetPage: payload,
            };
        case globalActionTypes.SET_SEARCH_TYPE:
            return {
                ...state,
                searchType: payload,
            };
        case globalActionTypes.SET_SELECTED_ITEM_CATEGORY:
            return {
                ...state,
                selectedItemCategory: payload,
                searchResetPage: true,
                selectedTag: null,
                selectedItemGroup: null,
                selectedItemModel: null,
                newProductsVisible: false,
            };
        case globalActionTypes.SET_SELECTED_ITEM_GROUP:
            return {
                ...state,
                selectedItemGroup: payload,
                searchResetPage: true,
                selectedTag: null,
                selectedItemModel: null,
            };
        case globalActionTypes.SET_SELECTED_ITEM_MODEL:
            return {
                ...state,
                selectedItemModel: payload,
                searchResetPage: true,
                selectedTag: null,
            };
        case globalActionTypes.SET_SELECTED_TAG:
            return {
                ...state,
                selectedTag: payload,
                selectedItemCategory: null,
                selectedItemGroup: null,
                selectedItemModel: null,
                selectedVendors: [],
                onlyInStock: false,
                searchResetPage: true,
                searchQuery: "",
                newProductsVisible: false,
            };
        case globalActionTypes.CHANGE_VAT:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    with_vat: payload,
                },
            };
        case globalActionTypes.CHANGE_DISCOUNT:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    with_discount: payload,
                },
            };

        case globalActionTypes.FETCH_PRODUCT_DETAILS_REQUEST:
            return {
                ...state,
                productDetails: {
                    ...state.productDetails,
                    list: {
                        ...state.productDetails.list,
                    },
                    isLoadingDetails: true,
                },
            };

        case globalActionTypes.FETCH_PRODUCT_DETAILS_SUCCESS:
            return {
                ...state,
                productDetails: {
                    ...state.productDetails,
                    list: {
                        ...state.productDetails.list,
                        ...payload,
                    },
                    isLoadingDetails: false,
                    errorDetails: null,
                },
                currentProduct: payload.item,
            };

        case globalActionTypes.FETCH_PRODUCT_DETAILS_FAILURE:
            return {
                ...state,
                productDetails: {
                    ...state.productDetails,
                    list: {
                        ...defaultGlobalState.productDetails.list,
                        vehicles: state.productDetails.list.vehicles,
                    },
                    isLoadingDetails: false,
                    error: payload,
                },
            };

        case globalActionTypes.FETCH_PRODUCT_DETAILS_VEHICLES_REQUEST:
            return {
                ...state,
                productDetails: {
                    ...state.productDetails,
                    list: {
                        ...state.productDetails.list,
                    },
                    isLoadingVehicles: true,
                },
            };

        case globalActionTypes.FETCH_PRODUCT_DETAILS_VEHICLES_SUCCESS:
            return {
                ...state,
                productDetails: {
                    ...state.productDetails,
                    list: {
                        ...state.productDetails.list,
                        vehicles: payload.results,
                    },
                    isLoadingVehicles: false,
                    errorDetails: false,
                },
            };

        case globalActionTypes.FETCH_PRODUCT_DETAILS_VEHICLES_FAILURE:
            return {
                ...state,
                productDetails: {
                    ...state.productDetails,
                    list: {
                        ...state.productDetails.list,
                        vehicles: [],
                    },
                    isLoadingVehicles: false,
                    errorVehicles: payload,
                },
            };
        
        case globalActionTypes.FETCH_ITEM_PURCHASE_HISTORY_REQUEST:
            return {
                ...state,
                productDetails: {
                    ...state.productDetails,
                    list: {
                        ...state.productDetails.list,
                    },
                    isLoadingPurchaseHistory: true,
                },
            };

        case globalActionTypes.FETCH_ITEM_PURCHASE_HISTORY_SUCCESS:
            return {
                ...state,
                productDetails: {
                    ...state.productDetails,
                    list: {
                        ...state.productDetails.list,
                        purchaseHistory: payload.results,
                    },
                    isLoadingPurchaseHistory: false,
                    errorDetails: false,
                },
            };

        case globalActionTypes.FETCH_ITEM_PURCHASE_HISTORY_FAILURE:
            return {
                ...state,
                productDetails: {
                    ...state.productDetails,
                    list: {
                        ...state.productDetails.list,
                        purchaseHistory: [],
                    },
                    isLoadingPurchaseHistory: false,
                    errorPurchaseHistory: payload,
                },
            };

        case globalActionTypes.CLEAR_PRODUCT_DETAILS:
            return {
                ...state,
                productDetails: {
                    ...defaultGlobalState.productDetails,
                    list: { ...defaultGlobalState.productDetails.list },
                    isVat: state.productDetails.isVat,
                    isDiscount: state.productDetails.isDiscount,
                },
                currentProduct: null,
            };

        case globalActionTypes.UPDATE_PRICES_DETAILS_REQUEST: {
            return {
                ...state,
                productDetails: {
                    ...state.productDetails,
                    list: {
                        ...state.productDetails.list,
                    },
                    isLoadingDetails: true,
                },
            };
        }

        case globalActionTypes.UPDATE_PRICES_DETAILS_SUCCESS: {
            return {
                ...state,
                productDetails: {
                    ...state.productDetails,
                    list: {
                        ...state.productDetails.list,
                        referenced_items_data: updateProductsPrices(
                            payload,
                            state.productDetails.list.referenced_items_data
                        ),
                    },
                    isLoadingDetails: false,
                },
                currentProduct: {
                    ...state.currentProduct,
                    prices: payload[state.currentProduct?.id]?.prices || state.currentProduct.prices
                }
            };
        }

        case globalActionTypes.UPDATE_PRICES_DETAILS_FAILURE: {
            return {
                ...state,
                productDetails: {
                    ...state.productDetails,
                    list: {
                        ...state.productDetails.list,
                    },
                    errorDetails: payload,
                },
            };
        }

        case globalActionTypes.UPDATE_RELATED_PRICES_DETAILS_SUCCESS: {
            return {
                ...state,
                productDetails: {
                    ...state.productDetails,
                    list: {
                        ...state.productDetails.list,
                        related_items_data: updateProductsPrices(
                            payload,
                            state.productDetails.list.related_items_data
                        ),
                    },
                    isLoadingDetails: false,
                },
                currentProduct: {
                    ...state.currentProduct,
                    prices: payload[state.currentProduct?.id]?.prices || state.currentProduct.prices
                }
            };
        }

        case globalActionTypes.UPDATE_RELATED_PRICES_DETAILS_FAILURE: {
            return {
                ...state,
                productDetails: {
                    ...state.productDetails,
                    list: {
                        ...state.productDetails.list,
                    },
                    errorDetails: payload,
                },
            };
        }

        case globalActionTypes.UPDATE_PRICES_DETAILS_FILTERS: {
            return {
                ...state,
                productDetails: {
                    ...state.productDetails,
                    list: { ...state.productDetails.list },
                    [payload.name]: payload.value,
                },
            };
        }

        case globalActionTypes.FETCH_OEM_VEHICLES_REQUEST:
            return {
                ...state,
                oemVehicles: {
                    ...defaultGlobalState.oemVehicles,
                    isLoading: true,
                },
            };

        case globalActionTypes.FETCH_OEM_VEHICLES_SUCCESS: {
            const lengthHistory = state.userData?.vin_search_history?.length;
            const vinHistory =
                lengthHistory === 10
                    ? state.userData?.vin_search_history?.slice(
                        0,
                        lengthHistory - 1
                    )
                    : state.userData?.vin_search_history;
            return {
                ...state,
                userData: {
                    ...state.userData,
                    vin_search_history: [state.searchQuery, ...vinHistory],
                },
                oemVehicles: {
                    ...state.oemVehicles,
                    ...payload,
                    error: null,
                    isLoading: false,
                },
                ...defaultGlobalState.currentOemVehicles,
            };
        }
        case globalActionTypes.FETCH_OEM_VEHICLES_FAILURE:
            return {
                ...state,
                oemVehicles: {
                    ...state.oemVehicles,
                    units: [...defaultGlobalState.oemVehicles.units],
                    categories: [...defaultGlobalState.oemVehicles.categories],
                    isLoading: false,
                    error: payload,
                },
            };

        case globalActionTypes.FETCH_OEM_VEHICLES_DETAILS_REQUEST:
            return {
                ...state,
                currentOemVehicles: {
                    ...state.currentOemVehicles,
                    isLoading: true,
                },
            };

        case globalActionTypes.FETCH_OEM_VEHICLES_DETAILS_SUCCESS:
            return {
                ...state,
                currentOemVehicles: {
                    ...payload,
                    units: [...(payload.units || [])],
                    categories: [...(payload.categories || [])],
                    error: null,
                    isLoading: false,
                },
            };

        case globalActionTypes.FETCH_OEM_VEHICLES_DETAILS_FAILURE:
            return {
                ...state,
                currentOemVehicles: {
                    ...defaultGlobalState.currentOemVehicles,
                    error: payload,
                },
            };

        case globalActionTypes.FETCH_CUSTOMER_SEARCH_LIST_REQUEST:
            return {
                ...state,
                customerSearchList: {
                    ...state.customerSearchList,
                    isLoading: true,
                },
            };

        case globalActionTypes.FETCH_CUSTOMER_SEARCH_LIST_SUCCESS:
            return {
                ...state,
                customerSearchList: {
                    ...payload,
                    error: null,
                    isLoading: false,
                },
            };

        case globalActionTypes.FETCH_CUSTOMER_SEARCH_LIST_FAILURE:
            return {
                ...state,
                customerSearchList: {
                    ...defaultGlobalState.customerSearchList,
                    error: payload,
                    isLoading: false,
                },
            };

        case globalActionTypes.SET_IMPERSONATED_COSTUMER_REQUEST:
            return {
                ...state,
                customerSearchList: {
                    isLoading: true,
                },
            };

        case globalActionTypes.SET_IMPERSONATED_COSTUMER_SUCCESS:
            return {
                ...state,
                customerSearchList: {
                    ...defaultGlobalState.customerSearchList,
                }
            };

        case globalActionTypes.SET_IMPERSONATED_COSTUMER_FAILURE:
            return {
                ...state,
                customerSearchList: {
                    isLoading: false,
                }
            };

        case globalActionTypes.CLEAR_IMPERSONATED_COSTUMER_SUCCESS:
            return {
                ...state,
                customerSearchList: {
                    ...defaultGlobalState.customerSearchList,
                }
            };

        case globalActionTypes.FETCH_ORDER_HISTORY_LIST_REQUEST:
            return {
                ...state,
                orderHistoryList: {
                    isLoading: true,
                },
            };

        case globalActionTypes.FETCH_ORDER_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                orderHistoryList: {
                    data: [...payload.results],
                    isLoading: false,
                }
            };

        case globalActionTypes.FETCH_ORDER_HISTORY_LIST_FAILURE:
            return {
                ...state,
                orderHistoryList: {
                    isLoading: false,
                }
            };

        case globalActionTypes.FETCH_ORDER_HISTORY_ITEM_REQUEST:
            return {
                ...state,
                orderHistoryItem: {
                    isLoading: true,
                },
            };

        case globalActionTypes.FETCH_ORDER_HISTORY_ITEM_SUCCESS:
            return {
                ...state,
                orderHistoryItem: {
                    ...payload,
                    isLoading: false,
                }
            };

        case globalActionTypes.FETCH_ORDER_HISTORY_ITEM_FAILURE:
            return {
                ...state,
                orderHistoryItem: {
                    isLoading: false,
                }
            };
        
        case globalActionTypes.FETCH_PRICE_OFFER_HISTORY_LIST_REQUEST:
            return {
                ...state,
                priceOfferHistoryList: {
                    isLoading: true,
                },
            };

        case globalActionTypes.FETCH_PRICE_OFFER_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                priceOfferHistoryList: {
                    data: [...payload.results],
                    isLoading: false,
                }
            };

        case globalActionTypes.FETCH_PRICE_OFFER_HISTORY_LIST_FAILURE:
            return {
                ...state,
                priceOfferHistoryList: {
                    isLoading: false,
                }
            };
        
        case globalActionTypes.FETCH_PRICE_OFFER_HISTORY_ITEM_REQUEST:
            return {
                ...state,
                priceOfferHistoryItem: {
                    isLoading: true,
                },
            };

        case globalActionTypes.FETCH_PRICE_OFFER_HISTORY_ITEM_SUCCESS:
            return {
                ...state,
                priceOfferHistoryItem: {
                    ...payload,
                    isLoading: false,
                }
            };

        case globalActionTypes.FETCH_PRICE_OFFER_HISTORY_ITEM_FAILURE:
            return {
                ...state,
                priceOfferHistoryItem: {
                    isLoading: false,
                }
            };

        case globalActionTypes.FETCH_TOP_PRODUCTS_REQUEST: {
            return {
                ...state,
                productsLoading: true,
                selectedTag: null,
                selectedItemCategory: null,
                selectedItemGroup: null,
                selectedItemModel: null,
                selectedVendors: [],
                searchQuery: "",
                onlyInStock: false,
                newProductsVisible: false,
            };
        }
        case globalActionTypes.FETCH_TOP_PRODUCTS_SUCCESS: {
            return {
                ...state,
                products: payload,
                pageCount: 1,
                productsLoading: false,
            };
        }
        case globalActionTypes.FETCH_TOP_PRODUCTS_FAILURE: {
            return {
                ...state,
                productsLoading: false,
            };
        }
        case globalActionTypes.FETCH_BY_CODE_SEARCH_LIST_REQUEST:
            return {
                ...state,
                byCodeSearchList: {
                    ...state.byCodeSearchList,
                    isLoading: true,
                },
            };

        case globalActionTypes.FETCH_BY_CODE_SEARCH_LIST_SUCCESS:
            return {
                ...state,
                byCodeSearchList: {
                    ...payload,
                    error: null,
                    isLoading: false,
                },
            };

        case globalActionTypes.FETCH_BY_CODE_SEARCH_LIST_FAILURE:
            return {
                ...state,
                byCodeSearchList: {
                    ...defaultGlobalState.byCodeSearchList,
                    error: payload,
                    isLoading: false,
                },
            };
        case globalActionTypes.CLEAR_BY_CODE_SEARCH_LIST:
            return {
                ...state,
                byCodeSearchList: {
                    ...defaultGlobalState.byCodeSearchList,
                },
            };

        case globalActionTypes.FETCH_FAVOURITES_REQUEST:
            return {
                ...state,
                favoritesList: {
                    ...state.favoritesList,
                    isLoading: true,
                },
            };

        case globalActionTypes.FETCH_FAVOURITES_SUCCESS:
            return {
                ...state,
                favoritesList: {
                    ...payload,
                    error: null,
                    isLoading: false,
                },
            };

        case globalActionTypes.FETCH_FAVOURITES_FAILURE:
            return {
                ...state,
                favoritesList: {
                    ...defaultGlobalState.favoritesList,
                    error: payload,
                    isLoading: false,
                },
            };
        
        case globalActionTypes.FETCH_AVAILABILITY_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                availabilityNotificationsList: {
                    ...state.availabilityNotificationsList,
                    isLoading: true,
                },
            };

        case globalActionTypes.FETCH_AVAILABILITY_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                availabilityNotificationsList: {
                    ...payload,
                    error: null,
                    isLoading: false,
                },
            };

        case globalActionTypes.FETCH_AVAILABILITY_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                availabilityNotificationsList: {
                    ...defaultGlobalState.availabilityNotificationsList,
                    error: payload,
                    isLoading: false,
                },
            };

        case globalActionTypes.FETCH_TAGS_REQUEST:
            return {
                ...state,
                tagsList: {
                    ...state.tagsList,
                    isLoading: true,
                },
            };

        case globalActionTypes.FETCH_TAGS_SUCCESS:
            return {
                ...state,
                tagsList: {
                    results: payload,
                    error: null,
                    isLoading: false,
                },
            };

        case globalActionTypes.FETCH_TAGS_FAILURE:
            return {
                ...state,
                tagsList: {
                    ...defaultGlobalState.tagsList,
                    error: payload,
                    isLoading: false,
                },
            };
        
        case globalActionTypes.FETCH_ITEM_TAG_LIST_REQUEST:
            return {
                ...state,
                tagItemList: {
                    ...state.tagItemList,
                    isLoading: true,
                },
            };

        case globalActionTypes.FETCH_ITEM_TAG_LIST_SUCCESS:
            return {
                ...state,
                tagItemList: {
                    results: payload,
                    error: null,
                    isLoading: false,
                },
            };

        case globalActionTypes.FETCH_ITEM_TAG_LIST_FAILURE:
            return {
                ...state,
                tagItemList: {
                    ...defaultGlobalState.tagItemList,
                    error: payload,
                    isLoading: false,
                },
            };
        
        case globalActionTypes.FETCH_TAG_ITEMS_REQUEST:
            return {
                ...state,
                productsLoading: true,
            };

        case globalActionTypes.FETCH_TAG_ITEMS_SUCCESS:
            return {
                ...state,
                pageCount: 1,
                productsLoading: false,
                products: payload.results,
            };

        case globalActionTypes.FETCH_TAG_ITEMS_FAILURE:
            return {
                ...state,
                productsLoading: false,
            };

        case globalActionTypes.FETCH_YQ_CATALOG_REQUEST:
            return {
                ...state,
                yqList: {
                    ...state.yqList,
                    isLoading: true,
                },
            };

        case globalActionTypes.FETCH_YQ_CATALOG_SUCCESS:
            return {
                ...state,
                yqList: {
                    results: payload,
                    error: null,
                    isLoading: false,
                },
            };

        case globalActionTypes.FETCH_YQ_CATALOG_FAILURE:
            return {
                ...state,
                yqList: {
                    ...defaultGlobalState.yqList,
                    error: payload,
                    isLoading: false,
                },
            };

        case globalActionTypes.FETCH_YQ_PARAMS_REQUEST:
            return {
                ...state,
                yqParams: {
                    ...state.yqParams,
                    isLoading: true,
                },
            };

        case globalActionTypes.FETCH_YQ_PARAMS_SUCCESS:
            return {
                ...state,
                yqParams: {
                    results: payload,
                    error: null,
                    isLoading: false,
                },
            };

        case globalActionTypes.FETCH_YQ_PARAMS_FAILURE:
            return {
                ...state,
                yqParams: {
                    ...defaultGlobalState.yqParams,
                    error: payload,
                    isLoading: false,
                },
            };

        case globalActionTypes.FETCH_YQ_LIST_VEHICLES_REQUEST:
            return {
                ...state,
                yqListVehicles: {
                    ...state.yqListVehicles,
                    isLoading: true,
                },
            };

        case globalActionTypes.FETCH_YQ_LIST_VEHICLES_SUCCESS:
            return {
                ...state,
                yqListVehicles: {
                    results: payload,
                    error: null,
                    isLoading: false,
                },
            };

        case globalActionTypes.FETCH_YQ_LIST_VEHICLES_FAILURE:
            return {
                ...state,
                yqListVehicles: {
                    ...defaultGlobalState.yqListVehicles,
                    error: payload,
                    isLoading: false,
                },
            };

        case globalActionTypes.FETCH_YQ_FIND_VEHICLE_REQUEST:
            return {
                ...state,
                yqFindVehicle: {
                    ...state.yqFindVehicle,
                    isLoading: true,
                },
            };

        case globalActionTypes.FETCH_YQ_FIND_VEHICLE_SUCCESS:
            return {
                ...state,
                yqFindVehicle: {
                    results: payload,
                    error: null,
                    isLoading: false,
                },
            };

        case globalActionTypes.FETCH_YQ_FIND_VEHICLE_FAILURE:
            return {
                ...state,
                yqFindVehicle: {
                    ...defaultGlobalState.yqFindVehicle,
                    error: payload,
                    isLoading: false,
                },
            };

        case globalActionTypes.FETCH_YQ_OEM_VEHICLES_REQUEST:
            return {
                ...state,
                oemVehicles: {
                    ...defaultGlobalState.oemVehicles,
                    isLoading: true,
                },
            };

        case globalActionTypes.FETCH_YQ_OEM_VEHICLES_SUCCESS: {
            return {
                ...state,
                oemVehicles: {
                    ...state.oemVehicles,
                    ...payload,
                    error: null,
                    isLoading: false,
                },
                ...defaultGlobalState.currentOemVehicles,
            };
        }
        case globalActionTypes.FETCH_YQ_OEM_VEHICLES_FAILURE:
            return {
                ...state,
                oemVehicles: {
                    ...state.oemVehicles,
                    units: [...defaultGlobalState.oemVehicles.units],
                    categories: [...defaultGlobalState.oemVehicles.categories],
                    isLoading: false,
                    error: payload,
                },
            };

        case globalActionTypes.FETCH_NEW_PRODUCTS_REQUEST: {
            return {
                ...state,
                productsLoading: true,
                selectedTag: null,
                selectedItemCategory: null,
                selectedItemGroup: null,
                selectedItemModel: null,
                selectedVendors: [],
                searchQuery: "",
                onlyInStock: false,
            };
        }
        case globalActionTypes.FETCH_NEW_PRODUCTS_SUCCESS: {
            return {
                ...state,
                products: payload.results,
                pageCount: payload.count / 25,
                productsLoading: false,
            };
        }
        case globalActionTypes.FETCH_NEW_PRODUCTS_FAILURE: {
            return {
                ...state,
                productsLoading: false,
                pageCount: 1,
            };
        }
        case globalActionTypes.SHOW_NEW_PRODUCTS: {
            return {
                ...state,
                newProductsVisible: payload,
            };
        }
        case globalActionTypes.FETCH_PLATE_VEHICLES_REQUEST:
            return {
                ...state,
                oemVehicles: {
                    ...defaultGlobalState.oemVehicles,
                    isLoading: true,
                },
            };
        case globalActionTypes.FETCH_PLATE_VEHICLES_SUCCESS: {
            return {
                ...state,
                oemVehicles: {
                    ...state.oemVehicles,
                    ...payload,
                    error: null,
                    isLoading: false,
                },
                ...defaultGlobalState.currentOemVehicles,
            };
        }
        case globalActionTypes.FETCH_PLATE_VEHICLES_FAILURE:
            return {
                ...state,
                oemVehicles: {
                    ...state.oemVehicles,
                    units: [...defaultGlobalState.oemVehicles.units],
                    categories: [...defaultGlobalState.oemVehicles.categories],
                    isLoading: false,
                    error: payload,
                },
            };
        case globalActionTypes.FETCH_INVOICES_REQUEST: {
            return {
                ...state,
                invoices: {
                    ...state.invoices,
                    isLoading: true,
                }
            };
        }
        case globalActionTypes.FETCH_INVOICES_SUCCESS: {
            return {
                ...state,
                invoices: {
                    ...state.invoices,
                    creditLimit: payload.credit_limit,
                    totalBalance: payload.total_balance,
                    unusedCreditLimit: payload.unused_credit_limit,
                    balance: payload.balance,
                    latePayments: payload.late_payments,
                    invoices: payload.invoices,
                    isLoading: false,
                }
            };
        }
        case globalActionTypes.FETCH_INVOICES_FAILURE: {
            return {
                ...state,
                invoices: {
                    ...defaultGlobalState.invoices,
                    isLoading: false,
                }
            };
        }
        case globalActionTypes.UPDATE_FORM_SUBMISSION_STATUS: {
            return {
                ...state,
                isFormSubmitted: payload,
            };
        }
        case globalActionTypes.FETCH_ITEM_RETURN_LIST_REQUEST:
            return {
                ...state,
                itemReturnList: {
                    isLoading: true,
                },
            };

        case globalActionTypes.FETCH_ITEM_RETURN_LIST_SUCCESS:
            return {
                ...state,
                itemReturnList: {
                    data: [...payload.results],
                    isLoading: false,
                }
            };

        case globalActionTypes.FETCH_ITEM_RETURN_LIST_FAILURE:
            return {
                ...state,
                itemReturnList: {
                    isLoading: false,
                }
            };

        case globalActionTypes.FETCH_ITEM_RETURN_ITEM_REQUEST:
            return {
                ...state,
                itemReturnItem: {
                    isLoading: true,
                },
            };

        case globalActionTypes.FETCH_ITEM_RETURN_ITEM_SUCCESS:
            return {
                ...state,
                itemReturnItem: {
                    ...payload,
                    isLoading: false,
                }
            };

        case globalActionTypes.FETCH_ITEM_RETURN_ITEM_FAILURE:
            return {
                ...state,
                itemReturnItem: {
                    isLoading: false,
                }
            };
        
        case globalActionTypes.FETCH_RETURN_FORM_DATA_REQUEST: {
            return {
                ...state,
                returnFormData: {
                    isLoading: true,
                }
            };
        }

        case globalActionTypes.FETCH_RETURN_FORM_DATA_SUCCESS: {
            return {
                ...state,
                returnFormData: {
                    locations: payload.locations,
                    isLoading: false,
                }
            };
        }

        case globalActionTypes.FETCH_RETURN_FORM_DATA_FAILURE: {
            return {
                ...state,
                returnFormData: {
                    isLoading: false,
                }
            };
        }

        case globalActionTypes.SET_LOGGING_IN: {
            return {
                ...state,
                loggingIn: payload,
            };
        }
        default:
            return state;
    }
};

export default globalReducer;
