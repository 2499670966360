import Table from "@material-ui/core/Table";
import styled, { css } from "styled-components";
import Typography from "../../../Typography";
import CallSplit from "@material-ui/icons/CallSplit";
import { theme } from "../../../../core/styles/theme";

export const StyledTable = styled(Table)(css`
        width: 100%;
        .MuiTableHead-root {
            .MuiTableCell-head {
                white-space: nowrap;
                font-size: 14px;
                font-weight: 500;
                border-bottom: none;
                padding: 6px 6px;
            }
        }
        .MuiTableBody-root {
            .MuiTableRow-root {
                width: 100%;
                background: #ffffff;
                box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
                border-radius: 2px;
                padding: 16px 0;
                transition: 0.05s;
                cursor: pointer;
                .MuiTableCell-root {
                    height: 70px;
                    font-size: 16px;
                    padding: 6px 6px; 
                }
                &:hover {
                    background:  #f3f3f3;
                }
            }
        }
`);

export const RowCellText = styled(Typography).attrs({
    color: "#37474F",
    customVariant: "bodySlim",
})`
    cursor: pointer;
`;

export const StyledCallSplit = styled(CallSplit)`
    color: ${theme.colors.orange};
`;
