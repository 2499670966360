export const LANGUAGES = {
    Latviešu: {
        // Latvia
        id: "lv",
        label: "Latviešu",
    },
    Lietuviškai: {
        id: "lt",
        label: "Lietuviškai",
    },
    Eesti: {
        // Estonian
        id: "et",
        label: "Eesti",
    },
    Suomi: {
        // Finnish
        id: "fi",
        label: "Suomi",
    },
    English: {
        // English
        id: "en",
        label: "English",
    },
    Norsk: {
        // Norwegian
        id: "nb",
        label: "Norsk (bokmål)",
    },
    Русский: {
        // Russian
        id: "ru",
        label: "Русский",
    },
    Svenska: {
        // Sweeden
        id: "sv",
        label: "Svenska",
    },
};

export const COUNTRIES = ["Latvia", "Estonia", "Lithuania", "Sweden", "Norway"];

/**
 * "scope" is part of the response that comes on /userinfo. It is the initial value for `country`.
 * It's sort of a custom "country code"
 * @param scope the custom "country code"
 * @returns the country name based on the provided country scope
 */
export const scopeToCountry = (scope) => {
    return COUNTRIES.find((country) =>
        country.toLowerCase().startsWith(scope.toLowerCase())
    );
};

export const countryToScope = (country) => {
    return country.substr(0, 3).toUpperCase();
};
